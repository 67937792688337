@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 100;
  src: url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2") format('woff2'),
  url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff") format('woff'),
  url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf") format('opentype');} 
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 300;
  src: url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2") format('woff2'),
  url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff") format('woff'),
  url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf") format('opentype');} 
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 400;
  src: url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2") format('woff2'),
  url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff") format('woff'),
  url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf") format('opentype');} 
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 500;
  src: url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2") format('woff2'),
  url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff") format('woff'),
  url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf") format('opentype');} 
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 700;
  src: url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2") format('woff2'),
  url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff") format('woff'),
  url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf") format('opentype');}
@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 900;
  src: url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2") format('woff2'),
  url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff") format('woff'),
  url("//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf") format('opentype');}

@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: 100;
  src: url("../fonts/SpoqaHanSansNeo-Thin.woff2") format("woff2"),
    url("../fonts/SpoqaHanSansNeo-Thin.woff") format("woff"),
    url("../fonts/SpoqaHanSansNeo-Thin.otf") format("truetype");
}

@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: 300;
  src: url("../fonts/SpoqaHanSansNeo-Light.woff2") format("woff2"),
    url("../fonts/SpoqaHanSansNeo-Light.woff") format("woff"),
    url("../fonts/SpoqaHanSansNeo-Light.otf") format("truetype");
}

@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: 400;
  src: local("SpoqaHanSansNeo Regular"),
    url("../fonts/SpoqaHanSansNeo-Regular.woff2") format("woff2"),
    url("../fonts/SpoqaHanSansNeo-Regular.woff") format("woff"),
    url("../fonts/SpoqaHanSansNeo-Regular.otf") format("truetype");
}

@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: 500;
  src: local("SpoqaHanSansNeo Medium"),
    url("../fonts/SpoqaHanSansNeo-Medium.woff2") format("woff2"),
    url("../fonts/SpoqaHanSansNeo-Medium.woff") format("woff"),
    url("../fonts/SpoqaHanSansNeo-Medium.otf") format("truetype");
}

@font-face {
  font-family: "SpoqaHanSansNeo";
  font-weight: 700;
  src: local("SpoqaHanSansNeo Bold"),
    url("../fonts/SpoqaHanSansNeo-Bold.woff2") format("woff2"),
    url("../fonts/SpoqaHanSansNeo-Bold.woff") format("woff"),
    url("../fonts/SpoqaHanSansNeo-Bold.otf") format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');